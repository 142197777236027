import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Navigation from 'components/layout/Navigation/index'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/Automatyka/About'
import Tiles from 'components/layout/Sections/Pages/Automatyka/Tiles'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Automatyka',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Automatyka',
    },
  ],
}

const AutomatykaPage: React.FC<PageProps> = ({ data }) => {
  const PAGE_SEO = data?.wpPage?.seo
  const PAGE = data?.wpPage?.automatyka

  const TILES = PAGE?.automaticCategory?.map((item) => ({
    img: item?.automaticCategoryImg?.localFile?.childImageSharp
      ?.gatsbyImageData!,
    alt: item?.automaticCategoryImg?.altText!,
    link: `${item.automaticCategoryLink}/`,
    label: item.automaticCategoryText,
  }))

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.automaticHeroImg?.localFile?.childImageSharp?.gatsbyImageData!
        }
        imgDesktop={
          PAGE?.automaticHeroImg?.localFile?.childImageSharp?.gatsbyImageData!
        }
        headingMobile={PAGE.automaticHeroTitle}
        headingDesktop={PAGE.automaticHeroTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={PAGE?.automaticDescription!} />
      <Tiles tiles={TILES} />
    </Layout>
  )
}

export default AutomatykaPage

export const query = graphql`
  query AutomatykaPage {
    wpPage(slug: { regex: "/automatyka/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      automatyka {
        automaticHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        automaticHeroTitle
        automaticDescription
        automaticCategory {
          automaticCategoryText
          automaticCategoryLink
          automaticCategoryImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
